export const publicationsList = [
  {
    text:"Borghouts, J., Huang, Y., Gibbs, S., Hopfer, S., Li, C., Mark, G. (2023). Understanding underlying moral values and language use of COVID-19 vaccine attitudes on Twitter. PNAS Nexus, 2, 1-12.",
    year: 2023,
    link: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC9991494/",
    },  
    {
    text:"Duong, H. T., Hopfer, S. (2023). Exploring intergenerational communication on social media group chats as a cancer prevention opportunity among Vietnamese American families. Journal of Medical Internet Research (JMIR) Formative Research, 7:e35601.",
    year: 2023,
    link: "https://formative.jmir.org/2023/1/e35601",
    }, 
    {
    text:"Kam, J., Hopfer, S., Cornejo, M., Murillo, R. M., Juarez, Dds, E. J. (2023). Latina/o/x undocumented college students’ perceived barriers and motivations for talking to a campus mental health professional: A focus on communication, culture, and structural barriers. Communication Monographs, accepted January 2023." ,
    year: 2023,
    link:"https://www.tandfonline.com/doi/full/10.1080/03637751.2023.2175881",
    },
    {
    text:"AuYoung, M., Espinoa, P. R., Chen, W. T., Juturu, P., Young, M. E., Casillas, A., Adkins-Jackson, P.B., Hopfer, S., Kissam, E., Alo, A. K., Vargas, R., Brown, A. (2022). Addressing racial/ethnic inequities in vaccine hesitancy and uptake: Lessons learned from the California Alliance against COVID-19, Journal of Behavioral Medicine, January 22: 1-14. PMCID: PMC8783654.",
    year:2023,
    link:"https://link.springer.com/article/10.1007/s10865-022-00284-8 ",
    },
  {
    text: "Fields, E. J., Hopfer, S., Warren, J. R., BeLue, R., Lebed, J., Hecht, M. L. (2022). Motivators and barriers to HPV vaccination: A qualitative study of underserved women attending Planned Parenthood. Vaccines. 10: 1126. Published July 15, 2022.",
    year:2022,
    link: "https://doi.org/10.3390/vaccines10071126",
  },
  {
    text: "Hopfer, S., Dyda, A., Brandt, H. M. (2022). Editorial: Digital solutions to HPV vaccination. Frontiers in Digital Health.",
    year: 2022,
    link: "https://doi.org/10.3389/fdgth.2022.972234",
  },
  {
    text: "Hopfer, S., Kieu-Diem Phillips, K., Weinzierl, M., Vasquez, H. E., Alkhatib, S., Harabagiu, S. M. (2022) Adaptation and dissemination of a National Cancer Institute evidence based HPV vaccine cancer control program (EBCCP) to the social media message environment. Frontiers in Digital Health (accepted June 8th,  2022).",
    year: 2022,
    link: "https://www.frontiersin.org/articles/10.3389/fdgth.2022.819228/fdgth-04-819228-html/full",
    category: "vaccine communication",
  },
  {
    text: "Weinzierl, M., Hopfer, S., Harabagiu, S. (2022). Scaling up the discovery of hesitancy profiles by identifying framing of beliefs towards vaccine confidence in Twitter discourse. Journal of Behavioral Medicine (May 30) PMCID: PMC9148945",
    year: 2022,
    link: "https://doi.org/10.1007/s10865-022-00328-z",
    category: "vaccine communication",
  },
  {
    text: "Hopfer, S., Fields, E. F., Ramirez, M., Long, S. N., Huszti, H., Gombosev, A., Boden-Albala, B., Sorkin, D. H., Cooper, D. M. (2022). Adolescent COVID-19 vaccine decision-making among parents in Southern California. International Journal of Environmental Research and Public Health (IJERPH), 19(7): 4212. PMID: 35409893 ",
    year: 2022,
    link: "https://doi.org/10.3390/ijerph19074212",
    category: ["vaccine communication", "covid-19"],
  },
  {
    text: "Garcia, S., Hopfer, S., Botes, E., Greiff, S. (2022) Associations Between Coronavirus Risk Perception,  Perceived Economic Risk of Coronavirus, General Self-efficacy, and Coronavirus Anxiety at the start of the pandemic: Differences by gender and race. IJERPH special issue on Mental Health in the Time of COVID-19 19(5), 2872. PMID: 35270567",
    year: 2022,
    link: "https://doi.org/10.3390/ijerph19052872",
    category: ["covid-19"],
  },
  {
    text: "AuYoung, M., Espinoa, P. R., Chen, W. T., Juturu, P., Young, M. E., Casillas, A., Adkins-Jackson, P.B., Hopfer, S., Kissam, E., Alo, A. K., Vargas, R., Brown, A. (2022). Addressing racial/ethnic inequities in vaccine hesitancy and uptake: Lessons learned from the California Alliance against COVID-19, Journal of Behavioral Medicine, January 22: 1-14. PMCID: PMC8783654",
    year: 2022,
    link: "https://doi.org/10.1007/s10865-022-00284-8",
    category: ["vaccine communication", "covid-19"],
  },
  {
    text: "Hopfer S, Fields EJ, Lu Y, Ramakrishnan G, Grover T, Bai Q, et al. (2021) The social amplification and attenuation of COVID-19 risk perception shaping mask-wearing behavior: A longitudinal twitter analysis. PLOS ONE. 16(9): e0257428",
    year: 2021,
    link: "https://doi.org/10.1371/journal.pone.0257428",
    category: "covid-19",
  },
  {
    text: "Garcia, S., Hopfer, S., Hortensia, A., Tanjasiri, S. (2021). HPV vaccine delay and refusal among unvaccinated Mexican American young adult women: A qualitative investigation of Mexican-born and US-born HPV vaccine decision narratives. Journal of Behavioral Medicine, May 25: 1-12, PMCID: PMC9130004",
    year: 2021,
    link: "https://doi.org/10.1007/s10865-022-00326-1",
    category: ["vaccine communication", "health disparities"],
  },
  {
    text: "Polonijo, A. N., Lee, S. S., Nagpal, N., Barros, R., Hopfer, S., Brown, B., Pellman, H., Singh, J. (2021). How do patient-provider relationship continuity, gender, and language affect pediatric HPV vaccine acceptance? Human Vaccines & Immunotherapeutics,17(11): 4467-4469. PMID: 34643482",
    year: 2021,
    link: "https://doi.org/10.1080/21645515.2021.1973322",
    category: "vaccine communication",
  },
  {
    text: "Hopfer, S., Duong, H. T., Garcia, S., Tanjasiri, S. P. (2021). Health information source characteristics matter: Adapting the dissemination of an HPV vaccine intervention to reach Latina and Vietnamese women. The Journal of Primary Prevention, 42(5), 511-529. PMID: 34283351",
    year: 2021,
    link: "https://doi.org/10.1007/s10935-021-00643-2",
  },
  {
    text: "Hecht, M.L., Belue, R., Ray, A., Hopfer, S., Miller-Day, M., & McKee, F. (2021). HPV Vaccine Intent among Adult Women Receiving Care at Community Health Centers. Journal of Cancer Education. PMID: 33400206",
    year: 2021,
    link: "https://doi.org/10.1007/s13187-020-01937-5",
    category: "health disparities",
  },
  {
    text: "Duong, H. & Hopfer, S. (2021). Let's Chat: Development of a family group chat cancer prevention intervention for Vietnamese families. Health Education & Behavior, 48(2), 208-219. PMID: 33586455",
    year: 2021,
    link: "https://doi.org/10.1177/1090198121990389",
    category: "health disparities",
  },
  {
    text: "Masri, S., Simolaris, A., Hopfer, S., Wu, J. (2020). Assessment of climate change sentiment, engagement, and adaptation through a community-based outreach campaign and questionnaire across the United States. Earth, 1(1), 75-96",
    year: 2020,
    link: "https://doi.org/10.3390/earth1010006",
    category: "climate change",
  },
  {
    text: "Duong, H., Hopfer, S. (2020) Let's Chat: A process evaluation of an intergenerational group chat intervention to increase cancer screening among Vietnamese American families. Translational Behavioral Medicine, 11(3), 891-900. PMID: 33290557",
    year: 2020,
    link: "https://doi.org/10.1093/tbm/ibaa120",
    category: "health disparities",
  },
  {
    text: "Matlock, M., Hopfer, S., Ogunseitan, O. A. (2019). Communicating Risk for a Climate-Sensitive Disease: A Case Study of Valley Fever in Central California. International Journal of Environmental Research and Public Health, 16(18), 3254",
    year: 2019,
    link: "https://doi.org/10.3390/ijerph16183254",
    category: "climate change",
  },
  {
    text: "Wilford, J. G., Hopfer, S., Wenzel, L. (2019). Perceptions of changes in clinical, informational, and supportive relationships after end of treatment among parents of young childhood cancer survivors. Psycho-oncology, 28(4): 913-919",
    year: 2019,
    link: "https://doi.org/10.1002/pon.5042",
  },
  {
    text: "Hopfer, S., Wright, M., Pellman, H., Wasserman, R., Fiks, A. (2019). HPV vaccine recommendation profiles among a national network of pediatric practitioners: Understanding contributors to parental hesitancy. Human Vaccines & Immunotherapeutics, 15 (7-8), 1776-1783. PMID: 30570419",
    year: 2019,
    link: "https://doi.org/10.1080/21645515.2018.1560771",
    category: "vaccine communication",
  },
  {
    text: "Hopfer, S., Ray, A. E., Hecht, M. L., Miller-Day, M., Belue, R., Zimet, G., Evans, W. D., McKee, F. X. (2018). Taking an HPV vaccine intervention to scale in a clinical setting. Translational Behavioral Medicine, 8(5), 745-752. PMCID: PMC5741467",
    year: 2018,
    link: "https://doi.org//10.1093/tbm/ibx066",
    category: "health disparities",
  },
  {
    text: "Hopfer, S., Garcia, S., Duong, H., Russo, J. A., Tanjasiri, S. P. (2017). A narrative engagement framework to understand HPV vaccination among Latina and Vietnamese women in a Planned Parenthood setting. Health Education & Behavior, 44(5), 1-10. PMCID: PMC5741467",
    year: 2017,
    link: "https://doi.org//10.1177/1090198117728761",
    category: "vaccine communication",
  },
  {
    text: "Brown, B., Hopfer, S., Chan, A. (2015). Improving human papillomavirus vaccine uptake: barriers and potential solutions. California Journal of Health Promotion, 13, vi-x",
    year: 2015,
    link: "https://journals.calstate.edu/cjhp/article/view/1818",
    category: "vaccine communication",
  },
  {
    text: "Hopfer, S., Tan, X., Wylie, J. (2014). A social network informed latent class analysis of patterns of substance use, sexual behavior, and mental health, Winnipeg, Canada. American Journal of Public Health, 104, 834-839. PMCID: PMC3987614 ",
    year: 2014,
    link: "https://doi.org//10.2105/AJPH.2013.301833",
  },
  {
    text: "Hopfer, S., Hecht, M. L., Lanza, S. T., Tan, X., Xu, S. (2013). Preadolescent drug use resistance skill profiles, substance Use, and substance use prevention. Journal of Primary Prevention, 34, 395-404. PMCID: PMC4089389",
    year: 2013,
    link: "https://doi.org//10.1007/s10935-013-0325-0",
  },
  {
    text: "Molloy, L. E., Moore, J. E., Trail, J., Van Epps, J. J., Hopfer, S. (2013). Understanding real-world implementation and “active ingredients” of PBIS. Prevention Science, 14, 593-605",
    year: "2013",
    link: "https://doi.org//10.1007/s11121-012-0343-9",
  },
  {
    text: "Kreager, D., Haynie, D., Hopfer, S. (2013). Dating and substance use in adolescent peer networks: A replication and extension. Addiction, 108, 638-647. PMCID: PMC3570706",
    year: 2013,
    link: "https://doi.org/10.1111/j.1360-0443.2012.04095.x",
  },
  {
    text: "Hopfer, S. (2012). Effects of a narrative HPV vaccine intervention aimed at reaching college women: A randomized controlled trial. Prevention Science, 13(2),173-182. PMID: 21993613",
    year: 2012,
    link: "https://doi.org/10.1007/s11121-011-0254-1",
    category: "health disparities",
  },
  {
    text: "Hopfer. S., Clippard, J. R. (2011). College women's HPV vaccine decision narratives. Qualitative Health Research, 21, 262-277. PMID: 20841433.",
    year: 2011,
    link: "https://doi.org/10.1177/1049732310383868",
    category: "vaccine communication",
  },
  {
    text: "Hopfer, S., Shin, Y., Davis, D., Elek, E., Kam, J. A., Hecht. M. L. (2010). A review of elementary school-based substance use prevention programs: Identifying program attributes. Journal of Drug Education, 40(1),11-36. PMCID 21038761.",
    year: 2010,
    link: "https://doi.org/10.2190/DE.40.1.b",
  },
  {
    text: "Ringwalt, C., Hecht, M. L., Hopfer, S. (2010). Drug prevention in elementary schools: An introduction to the special issue. Journal of Drug Education, 40(1), 1-9",
    year: 2010,
    link: "https://doi.org/10.2190/DE.40.1.a",
  },
  {
    text: "Warren, J. R., Allen, M. L., Hopfer, S., Okuyemi, K. (2010). Contextualizing single-parent preadolescent drug use talks. Qualitative Research Reports in Communication, 11(1), 29-36",
    year: 2010,
    link: "https://doi.org/10.1080/17459430903419504",
  },
  {
    text: "Parrott, R. L., Volkman, J. E., Lengerich, E. J., Ghetian, C., Chadwick, A. E., Hopfer. S. (2010). Using geographic information systems to promote community involvement in comprehensive cancer control. Health Communication, 25(3), 276-285",
    year: 2010,
    link: "https://doi.org/10.1080/10410231003711755",
    category: "community engagement",
  },
  {
    text: "Volkman, J. E., Parrott, R. L., Hopfer, S., Lengerich, E. J. (2010). A national survey of state comprehensive cancer control managers: Implications of geographic information systems. Journal of Cancer Education, 25(1), 55-60",
    year: 2010,
    link: "https://doi.org/10.1007/s13187-009-0012-2",
  },
  {
    text: "Hopfer, S., Chadwick, A. E., Parrott, R. L., Ghetian, C. B., Lengerich, E. J. (2009). Assessment of training needs and preferences for geographic information systems (GIS) mapping in state comprehensive cancer control programs. Health Promotion and Practice, 10, 579-587",
    year: 2009,
    link: "https://doi.org/10.1177/1524839907309047",
  },
  {
    text: "Kugel, C., Retzlaff, C., Hopfer, S., Lawson, D., Daley, E., Drewes, C., Freedman, S. (2009). Familias con Voz: Community survey results from an intimate partner violence prevention project with migrant workers. Journal of Family Violence, 24(8), 649-660",
    year: 2009,
    link: "https://doi.org/10.1007/s10896-009-9263-2",
  },
  {
    text: "Hopfer, S., MacEachren, A. M. (2007). Leveraging the potential of geospatial annotations for group decisions: A communication theory perspective. International Journal of Geographic Information Systems, 8(21), 921-934",
    year: 2007,
    link: "https://doi.org/10.1080/13658810701377780",
  },
  {
    text: "Parrott, R. L., Hopfer, S., Ghetian, C. B., Lengerich, E. J. (2007). Mapping as visual health communication tool: Promises and dilemmas. Health Communication, 22(1), 13-24",
    year: 2007,
    link: "https://doi.org/10.1080/10410230701310265",
    category: "community engagement",
  },
  {
    text: "Mansfield, C., Hopfer, S., Marteau, T. M. (1999). Termination rates after prenatal diagnosis of Down syndrome, spina bifida, anencephaly, Turner, and Klinefelter syndromes: A systematic literature review. European Concerted Action: Decision-making After the Diagnosis of a Fetal Abnormality. Prenatal Diagnosis, 19(9), 808-812",
    year: 1999,
    link: "https://pubmed.ncbi.nlm.nih.gov/10521836/",
  },
];
