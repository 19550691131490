import SectionTitle from "../SectionTitle/SectionTitle";
import hopfer from "./images/hopfer.jpeg";
import ExternalLinkedButton from "../../buttons/ExternalLinkedButton/ExternalLinkedButton";
import "./CSS/AboutHopfer.css";

function AboutHopfer() {
  return (
    <div className="aboutHopfer">
      <div className="headshotContainer">
        <img src={hopfer} alt="Dr. Suellen Hopfer" />
      </div>

      <div className="textContainer">
        <SectionTitle text="About Me" />
        <h2>About Dr. Hopfer</h2>
        <div className="biography">
          <p>
            Dr. Suellen Hopfer is an Assistant Professor in the Program in
            Public Health at UC Irvine. She received a master's in human
            genetics from the University of Arizona, a PhD in health
            communication from Penn State University, and completed
            post-doctorate training at Penn State Methodology Center. Her PhD
            involved communication theory and designing prevention
            interventions. Her post-doctorate training involved training in
            latent class analysis and multiphase optimization strategy (MOST)
            randomized trial designs. Guided by a framework to design prevention
            and communication interventions, Dr. Hopfer takes an ecological
            approach to analyze vaccine attitudes and climate change related
            community impacts.
          </p>
          <p>
            The Hopfer Lab uses qualitative and quantitative methods to examine
            communication phenomena relevant to public health (e.g.,
            misinformation and narratives related to HPV and COVID-19
            vaccination). A subset of research focuses on dissemination
            strategies to scale prevention interventions. Dr. Hopfer's
            intervention implementation research is not only guided by narrative
            communication theory, but also by frameworks for disseminating and
            adapting interventions to various contexts and multiple levels.
          </p>
          <p>
            Dr. Hopfer developed the first National Cancer Institute (NCI)
            nationally recognized evidence-based cancer control program (
            <a
              href="https://ebccp.cancercontrol.cancer.gov/programDetails.do?programId=22620324"
              target="_blank"
              rel="noopener noreferrer"
            >
              EBCCP
            </a>
            ) for making informed decisions about HPV vaccination. The program
            is disseminated currently at Planned Parenthood centers and is being
            tested in randomized trials for its effectiveness across University
            of Texas (UT) health centers.
          </p>
        </div>
        <div className="buttonContainer">
          <ExternalLinkedButton
            path="https://www.faculty.uci.edu/profile.cfm?faculty_id=6369"
            text="Faculty Profile"
          />
        </div>
      </div>
    </div>
  );
}

export default AboutHopfer;
