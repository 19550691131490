import hopfer from "./headshots/hopfer.jpeg";
import garcia from "./headshots/garcia.jpeg";
import duong from "./headshots/duong.jpeg";
import fields from "./headshots/fields.jpeg";
import ko from "./headshots/ko.jpg";
import sriperumbudur from './headshots/sriperumbudur.jpeg';
import ramirez from "./headshots/ramirez.png";
import long from "./headshots/long.jpeg";
import iyer from "./headshots/iyer.jpeg";
import chang from "./headshots/chang.jpeg";
import chal from "./headshots/chal.jpg";
import fan from "./headshots/fan.jpeg";
import harabagiu from "./headshots/harabagiu.jpg";
import lu from "./headshots/lu.jpg";
import wu from "./headshots/wu.jpg";
import li from "./headshots/li.png";
import millerDay from "./headshots/miller-day.jpeg";
import kam from "./headshots/kam.jpeg";
import wang from "./headshots/wang.png";


export const profiles = [
  {
    name: "Suellen Hopfer, PhD, CGC",
    position: "Assistant Professor",
    image: hopfer,
    alt: "Dr. Suellen Hopfer",
    bio: "Dr. Suellen Hopfer has a PhD in health communication and post-doctorate training in prevention methods and randomized trial designs. She has been teaching and conducting research at the University of California, Irvine (UCI) since 2015. She teaches health communication, risk communication, and qualitative methods at the graduate and undergraduate levels. Dr. Hopfer and her research team are passionate about addressing health disparities through research to improve public health outcomes and advance communication theory to meet the challenges of the 21 century. Dr. Hopfer’s research team collaborates with interdisciplinary teams across academic fields of computer science, urban policy, engineering, linguistics, statistics, and pediatrics and collaborates with community partners engaged in important work to improve the lives of communities who are disproportionately impacted by adverse health outcomes or adverse neighborhood environments.",
    research:
      "Dr. Hopfer's research team focuses on communication phenomena or contexts in which the framing of issues has the potential to make a difference in health outcomes and improve the lives of individuals, neighborhoods, and communities. The lab’s research investigates communication phenomena typically as independent variable predicting outcomes or shaping prevention interventions and policy or to understand risk perceptions but also investigate communication phenomena as outcome variables to uncover subgroups whose attitudes across a range of beliefs and values cluster together. Topic focus may include vaccine and climate change messaging and exposure to misinformation among populations disproportionately impacted by adverse health outcomes, parent-adolescent communication, youth involvement, school environments, and capacity building to mitigate adverse health outcomes. ",
    email: "shopfer@hs.uci.edu",
  },
  {
    name: "Samantha Garcia, PhD, MPH",
    position: "PhD Graduate",
    image: garcia,
    alt: "Samantha Garcia",
    bio: "Samantha Garcia is a PhD Graduate in the Hopfer lab and will be receiving her PhD in Public Health",
    research:
      "Her research uses health communication theory to advance health equity among adversely affected communities, primarily among Latinx communities. She explores potentially modifiable mechanisms that contribute to cervical cancer disparities among Latinx women in order to develop culturally appropriate community-based interventions. Currently, her work investigates individual (knowledge, perceptions, provider trust, cultural/religious beliefs), interpersonal (family opposition, provider communication) and structural influences (access to care, exposure to negative health messages) of HPV vaccine hesitancy among Mexican American young adult women and the role it plays on HPV vaccine uptake and completion.",
    email: "samantg4@uci.edu",
    type: "alumni",
  },
  {
    name: "Huong (Theresa) Duong, PhD",
    position: "PhD Graduate",
    image: duong,
    alt: "Huong (Theresa) Duong",
    bio: "Huong (Theresa) Duong is a Ph.D. Graduate on the Disease Prevention track in the Program in Public Health at UCI. She is a health disparities researcher who is interested in better understanding how culture, social networks, and communication processes can lead to pathways of behavioral change.",
    research:
      "Her prior work focused on designing and implementing “Let's Chat!”, a culturally tailored cancer prevention intervention among Vietnamese American families in Orange County. Her current research focuses on culture and its intersection with sexual health and HPV vaccination communication among Vietnamese American young adults. She hopes to continue this work after graduating, and extend her work to better understand COVID-19 vaccine hesitancy and mental health service seeking among minority populations.",
    email: "huongd@uci.edu",
    type: "alumni",
  },
  {
    name: "Jessie Ko",
    position: "PhD Student",
    image: ko,
    alt: "Jessie Ko",
    bio: "Jessie Ko is a PhD student in the Population Health and Disease Prevention track of the program in Public Health at UC Irvine. She received her Bachelor of Arts degree in Communication Studies and went on to studying Health Communication for her master's degree. After her academic training, she worked in public relations before starting to pursue her PhD studies.",
    research:
      "Her research interests are broadly in how social determinants of health influence people's preventive behaviors, particularly for underserved communities. She also has a strong interest in health intervention design.  Her research intentions are resulted from both her academic background in health communication and professional background in public relations. She hopes to apply her academic knowledge in public health and experience from public relations to carry out research that will inform the design of effective public health programs. ",
    email: "jessiewk@uci.edu",
    type: "alumni",
  },
  {
    name: "Emilia Fields",
    position: "PhD Student",
    image: fields,
    alt: "Emilia Fields",
    bio: "Emilia Fields is a PhD student in the Population Health and Diseases Prevention track of the Program in Public Health at UC Irvine. She received her Bachelor of Science degree in Microbiology, Immunology, & Molecular Genetics from the University of California, Los Angeles in 2010 and Master of Science degree in Global Health from the University of California, San Francisco in 2014. Emilia is also a certified Public Health Microbiologist in the state of California.",
    research:
      "Emilia's research interests lie in understanding vaccination behaviors and the vaccine decision making process among African American and Latinx communities. She is passionate about eliminating communicable disease health disparities among communities of color. Her current work is focused on investigating the COVID-19 vaccine decision making process among African American and Latinx parents residing in California. ",
    email: "fieldse@uci.edu",
  },

  {
    name: "Gomathi Sriperumbudur",
    position: "PhD Student",
    image: sriperumbudur,
    alt: "Gomathi Basham Sriperumbudur",
    bio: "I am a physician by training. After my completing my Diploma in Public Health, worked with the World Health Organization on a public health surveillance project in India and later as a Consultant on Adverse Events Following Immunization (AEFI) to the Ministry of Health and Family Welfare in the Indian government.",
    research:
      "Having been exposed to the vaccines in action at all levels, right from the frontline vaccine administration to the national policy and action plan on vaccine safety, I developed an interest in vaccine advocacy, the various factors that impact vaccine confidence, and health behavior in general.",
    email: "gsriperu@uci.edu ",
  },
  

  {
    name: "Yihang Fan",
    position: "PhD Student",
    image: fan,
    alt: "Yihang Fan",
    bio: "Yihang Fan is a PhD student in the Population Health and Disease Prevention track of the program in Public Health at UC Irvine. She received her Bachelor of Economy degree from China Pharmaceutical University in 2018 and her Master of Public Health degree from the University of Sydney in 2019. She worked in the cardiovascular clinical trial industry for years.",
    research:
      "Yihang's research interests will focus on the vaccine communication area (e.g., HPV vaccine, COVID-19 vaccine, influenza vaccine). She is passionate about discovering the barriers and facilitators of vaccine hesitancy and acceptance with the mixed method and developing effective health intervention components to impact health behavior change, eliminate health disparities, and improve vaccination coverage at the community and population level.",
    email: "yihangf1@uci.edu ",
  },
  {
    name: "Magdalen Ramirez",
    position: "Undergraduate Research Assistant",
    image: ramirez,
    alt: "Magdalen Ramirez",
    bio: "Magdalen Ramirez is an undergraduate student in the Hopfer lab.",
    research: "Magdalen Ramirez is studying communication in the Hopfer lab.",
    email: "magdalr2@uci.edu",
    type: "alumni",
  },

  {
    name: "Sorina Long",
    position: "Undergraduate Research Assistant",
    image: long,
    alt: "Sorina Long",
    bio: "Sorina Long is an undergraduate student in the Hopfer lab.",
    research: "Sorina Long is studying communication in the Hopfer lab.",
    email: "snlong@uci.edu",
    type: "alumni",
  },
  {
    name: "Dhriti Iyer",
    position: "Undergraduate Research Assistant",
    image: iyer,
    alt: "Dhriti Iyer",
    bio: "Dhriti Iyer is an undergraduate student in the Hopfer lab.",
    research: "Dhriti Iyer is studying communication in the Hopfer lab.",
    email: "dsiyer@uci.edu",
    type: "alumni",
  },
  {
    name: "Derek Chang",
    position: "Undergraduate Research Assistant",
    image: chang,
    alt: "Derek Chang",
    bio: "I am currently a third-year Public Health Sciences major with an academic and research interest in cancer epidemiology, health behavior, and health policy.",
    research:
      "I work with Dr. Hopfer on a UROP study about health behaviors surrounding mask-wearing during COVID-19 with perspectives from the Theory of Planned Behavior.",
    email: "ddchang1@uci.edu",
    type: "alumni",
  },
  {
    name: "Aiden Chal",
    position: "Undergraduate Research Assistant",
    image: chal,
    alt: "Aiden Chal",
    bio: "Originally from the Bay Area, Aiden Chal is a 3rd year double major in Public Health Policy + Environmental Science and Policy, with a minor in Urban and Regional Planning. In his free time, he likes to do some creative writing and enjoys searching up fun recipes to cook/bake with his roommates.",
    research:
      "His research interests are focused on the intersections between Public Health and Environmental Health, particularly in climate-change communication and Urban Planning efforts/policymaking. He also has a strong interest in the global health aspects of these intersections. Currently, he hopes to focus on more qualitative efforts in research in order to potentially pursue graduate studies in an Environmental Health Sciences MPH program.",
    email: "achal@uci.edu",
    type: "alumni",
  },
  {
    name: "Sanda Harabagiu",
    position: "Professor at UT Dallas",
    image: harabagiu,
    alt: "Sanda Harabagiu",
    bio: "I joined the Computer Science Department at U.T.Dallas in January 2002, coming from U.T. Austin, where I was a faculty member in the Department of Computer Sciences.",
    research:
      "My research interests include Natural Language Processing, Information Retrieval, Knowledge Processing, Artificial Intelligence and more recently Medical Informatics. I have been interested for a long time in Textual Question-Answering, reference resolution and textual cohesion and coherence. In 2006 I co-edited a book entitled Advances in Open Domain Question Answering. I have also co-organized several workshops, symposia, tutorials and research tracks focusing on Textual Question-Answering or Reference Resolution. In my research I combine knowledge extracted from the Web with knowledge coerced from large domain-specific knowledge bases (e.g. the Unified Medial Language System (UMLS) to model the semantics of language in texts. By learning knowledge embeddings for UMLS , superior recognition of relations in clinical documents can be achieved. UMLS knowledge embeddings can also inform the adversarial learning of biomedical knowledge embeddings used for alignining biomedial knowledge bases. More recently I have been fascinated by the discovery of misinformation on social media and its impact on vaccine hesitancy.",
    email: "sanda@utdallas.edu",
    type: "collaborator",
  },
  {
    name: "Qian Lu",
    position: "Professor at UT Houston",
    image: lu,
    alt: "Qian Lu",
    bio: "Professor, Department of Health Disparities Research, Division of OVP, Cancer Prevention and Population Sciences, The University of Texas MD Anderson Cancer Center, Houston, TX",
    research: "Health disparities",
    email: "",
    type: "collaborator",
  },
  {
    name: "Jun Wu",
    position: "Professor at UC Irvine",
    image: wu,
    alt: "Jun Wu",
    bio: "Jun Wu is Professor and Graduate Director of the Department of Environmental and Occupational Health in the Program in Public Health at UC-Irvine. She received her Bachelor of Engineering degree in Environmental Engineering from Tsinghua University, China in 1997, M.S. degree in Environmental Engineering from Penn State University in 2000, and Ph.D. degree in Environmental Health from University of California, Los Angeles in 2004.",
    research:
      "Dr. Wu's interests focus on population-based research of environmental exposure assessment, environmental epidemiology, and environmental health disparity. She has extensive experience and knowledge in examining the influences of various environmental exposures (e.g. air pollution, climate, and built environment such as green space, neighborhood resources, walkability) on reproductive outcomes (e.g. maternal and fetal health), children's health, and other health endpoints. She also has strong interest in research on environmental justice and environmental health disparity, particularly working in partner with communities.",
    email: "junwu@uci.edu",
    type: "collaborator",
  },
  {
    name: "Chen Li",
    position: "Professor at UC Irvine",
    image: li,
    alt: "Chen Li",
    bio: "Chen Li is a professor in the Department of Computer Science at UC Irvine. He received his Ph.D. degree in Computer Science from Stanford University, and his M.S. and B.S. in Computer Science from Tsinghua University, China, respectively.  He was a recipient of an NSF CAREER award and several test-of-time publication awards, a part-time visiting research scientist at Google, PC co-chair of VLDB 2015, and an ACM distinguished member. Since January 2020, he’s the treasurer and a board member of the VLDB Endowment.  Since July 2020, he is the Faculty Director of the ICS Master of Computer Science Program. He was a co-founder and CTO of a startup to commercialize his research results.",
    research:
      "My research interests are in the field of data management, including data-intensive computing, query processing and optimization, visualization, and text analytics. My current focus is building open source systems for big data management and analytics. My PhD thesis at Stanford was on data integration, with an emphasis on both theoretical and practical aspects. My recent research, especially after spending a few quarters at Google and a few years doing a startup as its founder and CTO, has a strong preference on engineering and open source system building.  I believe “Computer Science” is a “Science” to support great engineering, and we need to build systems to stay relevant in this fast-paced IT era. My recent research projects are closely related to social media data analytics due to its increasing importance in many disciplines.",
    email: "chenli@ics.uci.edu",
    type: "collaborator",
  },
  {
    name: "Michelle Miller-Day",
    position: "Professor at Chapman University",
    image: millerDay,
    alt: "Michelle Miller-Day",
    bio: "Michelle Miller-Day (Ph.D., Arizona State University) is a Professor of Communication Studies at Chapman University in Orange, California. Prior to joining Chapman in the Fall of 2012, Dr. Miller-Day occupied a faculty position at Penn State University at University Park as an Associate Professor of Communication Arts and Sciences and Biobehavioral Health and faculty affiliate with the Penn State Center for Health Care and Policy Research.",
    research:
      "Her cross-disciplinary work spans medicine, health, communication, and sociology and she has published numerous peer-reviewed articles, books, and chapters in a variety of outlets.",
    email: "millerda@chapman.edu",
    type: "collaborator",
  },
  {
    name: "Jennifer Kam",
    position: "Professor at UC Santa Barbara",
    image: kam,
    alt: "Jennifer Kam",
    bio: "Dr. Jennifer Kam is Professor and Vice Chair in the Department of Communication at the University of California, Santa Barbara (UCSB), and she is a Faculty Affiliate with the Chicana/o Studies Institute and the Migration Initiative at UCSB.",
    research:
      "Guided by a social ecological model of resilience, Dr. Kam uses quantitative and qualitative methods to examine how stressors, rooted in structural barriers, are associated with the health and wellbeing of immigrant youth. She focuses on undocumented immigration, interpreting for adult family members under stressful conditions, and racial/ethnic discrimination. In addition, she conducts research that identifies promotive factors at different levels (e.g., individual, interpersonal, community, institutional, cultural, state, and federal) to cultivate resilience and promote thriving.",
    email: "jkam@comm.ucsb.edu",
    type: "collaborator",
  },
  {
    name: "Wei Wang",
    position: "Professor at UCLA",
    image: wang,
    alt: "Wei Wang",
    bio: "Wei Wang is the Leonard Kleinrock Chair Professor in Computer Science and Computational Medicine at University of California, Los Angeles and the director of the Scalable Analytics Institute (ScAi). She is also a member of the UCLA Jonsson Comprehensive Cancer Center, Institute for Quantitative and Computational Biology, and Bioinformatics Interdepartmental Graduate Program. She received her PhD degree in Computer Science from the University of California, Los Angeles in 1999. She was a professor in Computer Science and a member of the Carolina Center for Genomic Sciences and Lineberger Comprehensive Cancer Center at the University of North Carolina at Chapel Hill from 2002 to 2012, and was a research staff member at the IBM T. J. Watson Research Center between 1999 and 2002.",
    research:
      "Dr. Wang's research interests include big data analytics, data mining, machine learning, natural language processing, bioinformatics and computational biology, and computational medicine. She has filed seven patents, and has published one monograph and more than two hundred eighty research papers in international journals and major peer-reviewed conference proceedings, including multiple best paper awards.",
    email: "weiwang@cs.ucla.edu",
    type: "collaborator",
  },
];
