import { useState } from "react";
import CarouselComponent from "./Carousel/Carousel";
import hopfer2 from "./Photos/hopfer2.jpeg";
import poster1 from "./Photos/poster1.JPG";
import poster3 from "./Photos/poster3.JPG";
import conference from "./Photos/conference.JPG";
import groupIndoors from "./Photos/HopferLabGroupPhoto.jpg";
import groupOutdoors from "./Photos/GroupPhotoOutdoors.jpg";
import beach from "./Photos/beach.jpg";
import uciAerial from "./Photos/uciAerial.jpeg";
import "./CSS/PhotoBox.css";
import "./CSS/PhotoBox_mobile.css";

function PhotoBox() {
  const [showBio, setShowBio] = useState(false);

  return (
    <div className="photoBoxComponent">
      <div
        className="photoContainer hopferPhotoContainer"
        onMouseEnter={() => setShowBio(true)}
        onMouseLeave={() => setShowBio(false)}
      >
        <img
          src={hopfer2}
          alt="Suellen Hopfer"
          className={showBio ? "blurredImage" : null}
        ></img>
        {showBio && (
          <div className="hopferBio">
            <p>
              Dr. Suellen Hopfer is an Assistant Professor in the Department of
              Health, Society & Behavior in the Program in Public Health at the
              University of California, Irvine (UCI).
            </p>
            <p>
              She received her doctoral training in health communication from
              Penn State University, which involved training in communication
              theory and designing prevention interventions.
            </p>
            <p>
              Dr. Hopfer investigates how communication phenomena (messaging,
              narratives, networks) impact health outcomes, risk perceptions,
              and health behavior change. Dr. Hopfer's research also involves
              designing, implementing and evaluating communication interventions
              that are culturally grounded.
            </p>
          </div>
        )}
      </div>
      <div className="photoContainer-Carousel">
        <CarouselComponent
          images={[
            poster1,
            groupOutdoors,
            // poster2,
            poster3,
            groupIndoors,
            conference,
            beach,
          ]}
        />
      </div>
      <div className="photoContainer">
        <img src={uciAerial} alt="UC Irvine campus" />
      </div>
      <div className="mobilePhotoCarousel">
        <CarouselComponent
          images={[hopfer2, groupOutdoors, conference, beach, uciAerial]}
        />
      </div>
    </div>
  );
}

export default PhotoBox;
